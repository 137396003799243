import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import Signup from '../signup';
import './App.css';
import Login from '../login';
import Profile from '../profile';
import ProfileShow from '../profileshow';
import Search from '../search';
import Suggestions from '../suggestions';
import Visit from '../visit';
import Like from '../like';
import Home from '../home';
import { AuthProvider } from './AuthContext';
import SecureRoute from './SecureRoute';
import NotLoggedRoute from './NotLoggedRoute';
import 'react-toastify/dist/ReactToastify.css';
import Nav from '../nav';
import Toaster from '../toaster';
import UserValidation from '../uservalidation';
import ResetForgotPassword from '../ResetforgotPassword';
import ForgotPassword from '../forgotpassword';
import Chat from '../chat';
import ChatRoom from '../chatroom';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUn1HXi2AnRBRfGxV2LftsqEHazINnVOo",
  authDomain: "speak-easy-dating.firebaseapp.com",
  projectId: "speak-easy-dating",
  storageBucket: "speak-easy-dating.appspot.com",
  messagingSenderId: "715185988679",
  appId: "1:715185988679:web:4395402f4682809ccd7d62",
  measurementId: "G-F0TMWS5EEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <AuthProvider>
      <Router>
        <Nav />
        <Switch>
          <NotLoggedRoute path="/signup" component={Signup} />
          <NotLoggedRoute path="/login" component={Login} />
          <NotLoggedRoute
            path="/validation/newaccount/:token"
            component={UserValidation}
          />
          <NotLoggedRoute path="/forgotpassword" component={ForgotPassword} />
          <NotLoggedRoute
            path="/validation/forgotpassword/:token"
            component={ResetForgotPassword}
          />
          <SecureRoute path="/search" component={Search} />
          <SecureRoute path="/suggestions" component={Suggestions} />
          <SecureRoute path="/profile/:username" component={ProfileShow} />
          <SecureRoute path="/profile" component={Profile} />
          <SecureRoute path="/visits" component={Visit} />
          <SecureRoute path="/likes" component={Like} />
          <SecureRoute path="/chat" component={Chat} />
          <SecureRoute path="/chatroom/:matchId" component={ChatRoom} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
      <Toaster />
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
