import React from 'react';
import ReactDOM from 'react-dom';
// import './components/app/index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUn1HXi2AnRBRfGxV2LftsqEHazINnVOo",
  authDomain: "speak-easy-dating.firebaseapp.com",
  projectId: "speak-easy-dating",
  storageBucket: "speak-easy-dating.appspot.com",
  messagingSenderId: "715185988679",
  appId: "1:715185988679:web:4395402f4682809ccd7d62",
  measurementId: "G-F0TMWS5EEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
